<template>
  <div class="map">
    <div class="container mt-4">
      <v-select
          :items="items"
          v-model="selectedMap"
          label="Выберите карту"
          dense
          outlined
      ></v-select>
    </div>
    <iframe :src="urls[selectedMap]" frameborder="0"></iframe>
  </div>
</template>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
.map {
  height: 80vh;
}
</style>

<script>
export default {
  name: "Map",
  data: () => {
    return {
      selectedMap: 'Арес',
      urls: {
        'Арес': 'https://map2.minetown.ru/',
        'Аполлон': 'https://map1.minetown.ru/'
      },
      items: ['Арес', 'Аполлон']
    };
  }
};
</script>
